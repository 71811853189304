.Draft-stars-preview {
    max-height: 1000px;
    max-width: 100%;
}

@media(max-width: 500px){
    .Draft-stars-preview {
        max-height: 400px;
        max-width: 100%;
    }
}