.Section {
  /* text-align: center; */
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: var(--font-color);
}



.Section-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Section-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media(max-width: 500px){
  .Section {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .Section-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .Section-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}