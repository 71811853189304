.Cube {
    max-height: 100%;
    color:var(--font-color); 
    font-size: 12px;
    overflow: hidden;
}


@media(max-width: 500px){
    .Cube {
        max-height: 40%;
        font-size: 6px;
    }
}

.Name-text {
    z-index: 1;
    background-color: var(--background-color);
    border-radius: 5px;
}

.icon-row img {
    width: 50px;
    padding: 5px;
}

.icon-row img:hover {
    width: 60px;
    padding: 0px;
}

@media(prefers-color-scheme: dark){
    .icon-row {
        filter: invert()
    }
}

.email-link {
    font-size: calc(3px + 2vmin);
}