:root {
  --background-color: white;
  --font-color: #282c34;
}

@media(prefers-color-scheme: dark){
  :root {
    --background-color: #282c34;
    --font-color: white;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--font-color);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a:link, a:visited {
  color: var(--font-color);
  text-decoration: underline;
  cursor: auto;
}

a:link:active, a:visited:active {
  color: var(--font-color);
}

.external-link::after {
  content: '\2197'; /* Unicode for the arrow icon */
  font-size: 0.8em; /* Adjust the size of the icon */
  margin-left: 5px; /* Add some space between the text and the icon */
}